import React from "react"

import Job from "../components/job"
import {
  JobFeature,
  JobRole,
  JobRequirements,
} from "../components/job-components"

const JobSoftwareDeveloper = () => {
  return (
    <Job
      title="Senior Software Engineer - AWS, Python, JavaScript"
      location="Hybrid Office in Linz; Remote in Austria"
      workingTime="Full-time (38.5h)"
      startDate="ASAP"
      annualSalary="EUR 50,000"
    >
      <h3 className="my-5">Engineering at Celantur</h3>
      <JobFeature>
        Processing text information is nice, but have you ever built a scalable
        system that works with peak loads of Terabytes of image and video data?
        – We do that.
      </JobFeature>
      <JobFeature>
        While processing millions of files and objects, every millisecond
        counts. By solving small but hard algorithmic challenges, we can reap
        big performance rewards.{" "}
      </JobFeature>
      <JobFeature>
        As a growing startup, everyone has to take over responsibilities. Your
        initiative spirit will be a major contribution to the team’s success!
      </JobFeature>
      <JobFeature>
        We speak many languages, but our favourites are Python (backend) and
        React (frontend). We also use AWS managed services and Docker
        containers.
      </JobFeature>
      <JobFeature>
        We have a highly experienced product team which is looking forward to you expertise.
      </JobFeature>
      <JobFeature>
        Our customers are world-class companies, therefore we’re exposed to a
        lot of exciting and highly technical use-cases in Reality Capture,
        Construction, Automotive and Big Data.
      </JobFeature>

      <JobRole>
        <JobFeature>
          You are polyglottal in Python and JavaScript. (C/C++, CUDA are a plus). Experience with Machine Learning is beneficial.
        </JobFeature>
        <JobFeature>
          You are experienced with AWS services (ECS, IAM, SQS, DynamoDB etc), as well as database modelling.
        </JobFeature>
        <JobFeature>
          As part of the team, you'll apply SCRUM, DevOps and Test Driven Development principles.
        </JobFeature>
        <JobFeature>
          You propose and make technical decisions, while having a hands-on mentality.
        </JobFeature>
        <JobFeature>
          You make complex code run fast while keeping it clean and readable.
        </JobFeature>
        <JobFeature>
          You’d rather write a Bash script than manually executing a sequence of commands thrice.
        </JobFeature>
        <JobFeature>
          You can translate the technical jargons to English and explain your work to a non tech-savvy person.
        </JobFeature>
        <JobFeature>
          You can get into the mindset of a customer easily and write documentation that makes a difference in user experience.
        </JobFeature>
        <JobFeature>
          You work in a technically diverse team with performance engineers,
          machine learning engineers and product managers.
        </JobFeature>
        <JobFeature>You care for data protection and IT security.</JobFeature>

      </JobRole>

      <h3 className="my-5">What we offer</h3>
      <JobFeature>
        Exciting, modern tech stacks and work culture that you can actively
        shape
      </JobFeature>
      <JobFeature>
        Work with our young and international team on high-tech solutions and
        services
      </JobFeature>
      <JobFeature>
        Career opportunities as part of a growing startup in an emerging market
      </JobFeature>
      <JobFeature>
        Flexible working hours, up to 3 days of home office per week and possibility of remote work
      </JobFeature>
      <JobFeature>
        An office at Tabakfabrik Linz - one of the hearts of Austria’s start-up
        scene - with a diverse set of great places for lunch and leisure (e.g.
        Donaulände)
      </JobFeature>
    </Job>
  )
}

export default JobSoftwareDeveloper
